import React, { useContext } from "react";
import { Button } from "reactstrap";
import { observer } from "mobx-react-lite";

import AudioStore from "../stores/audioStore";
import audioOnIcon from "../assets/icons/speaker.png";

const AudioOnButton = () => {
  const audioStore = useContext(AudioStore);
  const { turnOffAudio } = audioStore;
  return (
    <Button onClick={() => turnOffAudio()} color="link" className="icon-button">
      <img src={audioOnIcon} alt=""></img>
    </Button>
  );
};

export default observer(AudioOnButton);
