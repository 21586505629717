import React from "react";
import "./MailToLink.css";

const MailToLink = () => {
  return (
    <div className="contact-email-holder">
      <a
        className="contact-email"
        href="mailto:mustafademirtas@gmail.com?subject=Palaspandaras Timer App"
      >
        Email Me
      </a>
    </div>
  );
};
export default MailToLink;
