import React, { useContext } from "react";
import { observer } from "mobx-react-lite";

import TimerStore, { StatusType } from "../stores/timerStore";
import TimerStartButton from "./TimerStartButton";
import TimerPauseButton from "./TimerPauseButton";

const TimerToggler = () => {
  const timerStore = useContext(TimerStore);
  const { currentStatusType } = timerStore;
  return (
    <>
      {currentStatusType === StatusType.Play && (
        <TimerPauseButton></TimerPauseButton>
      )}
      {(currentStatusType === StatusType.Pause ||
        currentStatusType === StatusType.Completed) && (
        <TimerStartButton></TimerStartButton>
      )}
    </>
  );
};

export default observer(TimerToggler);
