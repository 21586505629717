import { observable, action } from "mobx";
import { createContext } from "react";

export enum AudioStatus {
  Off,
  On,
}

const AUDIO_STATUS_KEY: string = "audio_status";

const getAudioStatusFromLocalStorage = () => {
  const result = localStorage.getItem(AUDIO_STATUS_KEY);
  if (result) {
    if (parseInt(result) === 0) return AudioStatus.Off;
    else return AudioStatus.On;
  }
  return AudioStatus.Off;
};
const setAudioStatusFromLocalStorage = (audioStatus: AudioStatus) => {
  localStorage.setItem(AUDIO_STATUS_KEY, audioStatus.toString());
};

class AudioStore {
  @observable audioStatus: AudioStatus;

  constructor() {
    this.audioStatus = getAudioStatusFromLocalStorage();
  }

  @action turnOnAudio = () => {
    this.audioStatus = AudioStatus.On;
    setAudioStatusFromLocalStorage(AudioStatus.On);
  };

  @action turnOffAudio = () => {
    this.audioStatus = AudioStatus.Off;
    setAudioStatusFromLocalStorage(AudioStatus.Off);
  };
}

export default createContext(new AudioStore());
