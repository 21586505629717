import React, { useContext } from "react";
import { Button } from "reactstrap";
import { observer } from "mobx-react-lite";

import TimerStore from "../stores/timerStore";
import playIcon from "../assets/icons/play.png";

const TimerStartButton = () => {
  const timerStore = useContext(TimerStore);
  const { play } = timerStore;
  return (
    <Button onClick={() => play()} color="link" className="icon-button">
      <img src={playIcon} alt=""></img>
    </Button>
  );
};

export default observer(TimerStartButton);
