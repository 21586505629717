import React from "react";
import "./AppLogo.css";
import logo from "../assets/logo/P_LOGO.png";

const AppLogo = () => {
  return (
    <div className="app-logo-holder">
      <a className="app-logo-link" href="/">
        <img className="app-logo" src={logo} alt="Palaspandaras"></img>
      </a>
    </div>
  );
};

export default AppLogo;
