import React, { useContext } from "react";
import { Button } from "reactstrap";
import { observer } from "mobx-react-lite";

import AudioStore from "../stores/audioStore";
import audioOffIcon from "../assets/icons/speaker-off.png";

export const AudioOffButton = () => {
  const audioStore = useContext(AudioStore);
  const { turnOnAudio } = audioStore;
  return (
    <Button
      onClick={() => turnOnAudio()}
      color="link"
      className="icon-button"
    >
      <img
        src={audioOffIcon}
        alt=""
      ></img>
    </Button>
  );
};
export default observer(AudioOffButton);
