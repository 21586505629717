import React, { useContext } from "react";
import { observer } from "mobx-react-lite";
import { Helmet } from "react-helmet";

import TimerStore from "../stores/timerStore";
import "./TimerDisplay.css";

const TimerDisplay = () => {
  const timerStore = useContext(TimerStore);
  const { readableRemainingTime, currentEmoji } = timerStore;

  return (
    <>
      <Helmet defer={false}>
        <title>
          {readableRemainingTime} - {currentEmoji}
        </title>
      </Helmet>
      <span className="timer-text">{readableRemainingTime}</span>
    </>
  );
};

export default observer(TimerDisplay);
