import React, { useContext, useRef, useEffect } from "react";
import { observer } from "mobx-react-lite";

import AudioStore, { AudioStatus } from "../stores/audioStore";
import TimerStore, { StatusType } from "../stores/timerStore";
import AudioOnButton from "./AudioOnButton";
import AudioOffButton from "./AudioOffButton";

const ticktockSound = require("../assets/sounds/tick-tock.mp3");
const doneSound = require("../assets/sounds/done.mp3");

const AudioToggler = () => {
  const audioStore = useContext(AudioStore);
  const timerStore = useContext(TimerStore);
  const ticktockSoundRef = useRef<HTMLAudioElement>(null);
  const doneSoundRef = useRef<HTMLAudioElement>(null);
  const { audioStatus } = audioStore;
  const { currentStatusType } = timerStore;

  useEffect(() => {
    if (
      audioStatus === AudioStatus.On &&
      currentStatusType === StatusType.Play
    ) {
      ticktockSoundRef.current?.play();
    } else {
      ticktockSoundRef.current?.pause();
      ticktockSoundRef.current!.currentTime = 0;
    }
  }, [audioStatus, currentStatusType]);

  useEffect(() => {
    if (currentStatusType === StatusType.Completed) {
      doneSoundRef.current!.play();
    }
  }, [currentStatusType]);

  return (
    <>
      {audioStatus === AudioStatus.Off && <AudioOffButton></AudioOffButton>}
      {audioStatus === AudioStatus.On && <AudioOnButton></AudioOnButton>}
      <audio ref={ticktockSoundRef} loop>
        <source src={ticktockSound} type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
      <audio ref={doneSoundRef}>
        <source src={doneSound} type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
    </>
  );
};

export default observer(AudioToggler);
