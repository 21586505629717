import React, { useContext } from "react";
import { Button } from "reactstrap";
import { observer } from "mobx-react-lite";

import TimerStore from "../stores/timerStore";
import pauseIcon from "../assets/icons/pause.png";

const TimerPauseButton = () => {
  const timerStore = useContext(TimerStore);
  const { pause } = timerStore;

  return (
    <Button onClick={() => pause()} color="link" className="icon-button">
      <img
        src={pauseIcon}
        alt=""
      ></img>
    </Button>
  );
};

export default observer(TimerPauseButton);
