import React, { FC, useState, useContext } from "react";
import {
  Button,
  ModalBody,
  ModalHeader,
  Modal,
  Form,
  FormGroup,
  Label,
  Input,
  CustomInput,
} from "reactstrap";
import { observer } from "mobx-react-lite";

import settingsIcon from "../assets/icons/settings.png";
import TimerStore from "../stores/timerStore";
import "./SettingsButton.css";

interface IProps {}

const SettingsButton: FC<IProps> = () => {
  const timerStore = useContext(TimerStore);
  const { rule, updateRule } = timerStore;
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const closeBtn = (
    <button className="close text-white" onClick={toggle}>
      &times;
    </button>
  );

  const onLongBreakChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.currentTarget.value);
    const newRule = rule;
    newRule.longBreak = parseInt(event.currentTarget.value);
    updateRule(newRule);
  };

  const onShortBreakChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.currentTarget.value);
    const newRule = rule;
    newRule.shortBreak = parseInt(event.currentTarget.value);
    updateRule(newRule);
  };

  const onDoTimeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.currentTarget.value);
    const newRule = rule;
    newRule.workTime = parseInt(event.currentTarget.value);
    updateRule(newRule);
  };

  return (
    <>
      <Button onClick={() => toggle()} color="link" className="icon-button">
        <img src={settingsIcon} alt=""></img>
      </Button>
      <Modal isOpen={modal} toggle={toggle} className="custom-modal">
        <ModalHeader toggle={toggle} close={closeBtn} className="text-white">
          Settings
        </ModalHeader>
        <ModalBody>
          <Form>
            <div className="container">
              <div className="row">
                <div className="col">
                  <FormGroup tag="fieldset">
                    <legend>Time To Do</legend>
                    <FormGroup check>
                      <CustomInput
                        id="dotime20"
                        type="radio"
                        name="dotime"
                        value={20}
                        defaultChecked={rule.workTime === 20}
                        onChange={onDoTimeChanged}
                        label="20 Minute"
                      />
                    </FormGroup>
                    <FormGroup check>
                      <CustomInput
                        id="dotime25"
                        type="radio"
                        name="dotime"
                        value={25}
                        defaultChecked={rule.workTime === 25}
                        onChange={onDoTimeChanged}
                        label="25 Minute"
                      />
                    </FormGroup>
                    <FormGroup check>
                      <CustomInput
                        id="dotime30"
                        type="radio"
                        name="dotime"
                        value={30}
                        defaultChecked={rule.workTime === 30}
                        onChange={onDoTimeChanged}
                        label="30 Minute"
                      />
                    </FormGroup>
                    <FormGroup check>
                      <CustomInput
                        id="dotime40"
                        type="radio"
                        name="dotime"
                        value={40}
                        defaultChecked={rule.workTime === 40}
                        onChange={onDoTimeChanged}
                        label="40 Minute"
                      />
                    </FormGroup>
                    <FormGroup check>
                      <CustomInput
                        id="dotime50"
                        type="radio"
                        name="dotime"
                        value={50}
                        defaultChecked={rule.workTime === 50}
                        onChange={onDoTimeChanged}
                        label="50 Minute"
                      />
                    </FormGroup>
                  </FormGroup>
                </div>
                <div className="col">
                  <FormGroup tag="fieldset">
                    <legend>Short Break</legend>
                    <FormGroup check>
                      <CustomInput
                        id="shorttime01"
                        type="radio"
                        name="shorttime"
                        value={5}
                        defaultChecked={rule.shortBreak === 5}
                        onChange={onShortBreakChanged}
                        label="5 Minute"
                      />
                    </FormGroup>
                    <FormGroup check>
                      <CustomInput
                        id="shorttime02"
                        type="radio"
                        name="shorttime"
                        value={10}
                        defaultChecked={rule.shortBreak === 10}
                        onChange={onShortBreakChanged}
                        label="10 Minute"
                      />
                    </FormGroup>
                  </FormGroup>
                  <FormGroup tag="fieldset">
                    <legend>Long Break</legend>
                    <FormGroup check>
                      <CustomInput
                        id="longtime01"
                        type="radio"
                        name="longtime"
                        value={20}
                        defaultChecked={rule.longBreak === 20}
                        onChange={onLongBreakChanged}
                        label=" 20 Minute"
                      />
                    </FormGroup>
                    <FormGroup check>
                      <CustomInput
                        id="longtime02"
                        type="radio"
                        name="longtime"
                        value={30}
                        defaultChecked={rule.longBreak === 30}
                        onChange={onLongBreakChanged}
                        label=" 30 Minute"
                      />
                    </FormGroup>
                  </FormGroup>
                </div>
              </div>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default observer(SettingsButton);
