import React, { useContext } from "react";
import { Button } from "reactstrap";
import { observer } from "mobx-react-lite";

import TimerStore from "../stores/timerStore";
import resetIcon from "../assets/icons/replay.png";

const TimerResetButton = () => {
  const timerStore = useContext(TimerStore);
  const { reset } = timerStore;
  return (
    <Button onClick={() => reset()} color="link" className="icon-button">
      <img

        src={resetIcon}
        alt=""
      ></img>
    </Button>
  );
};

export default observer(TimerResetButton);
