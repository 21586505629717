import { observable, action } from "mobx";
import { createContext } from "react";
import { duration } from "moment";
import LocalStorageManager from "../utilities/localStorageManager";

export interface Rule {
  workTime: number;
  shortBreak: number;
  longBreak: number;
}

enum TimeType {
  Work,
  ShortBreak,
  LongBreak,
}

export enum StatusType {
  Play,
  Pause,
  Completed,
}

const sequance: Array<TimeType> = [
  TimeType.Work,
  TimeType.ShortBreak,
  TimeType.Work,
  TimeType.ShortBreak,
  TimeType.Work,
  TimeType.ShortBreak,
  TimeType.Work,
  TimeType.LongBreak,
];

export interface ITimer {
  play(): void;
  pause(): void;
  reset(): void;
}

const convertSecondToReadableTime = (seconds: number) => {
  const dur = duration(seconds, "seconds");
  let min = dur.minutes().toString();
  let sec = dur.seconds().toString();

  if (min.length < 2) min = `0${min}`;
  if (sec.length < 2) sec = `0${sec}`;
  return `${min}:${sec}`;
};

class TimerStore {
  @observable readableRemainingTime: string;
  @observable currentStatusType: StatusType;
  @observable progress: number;
  @observable currentSet: number;
  @observable rule: Rule;
  @observable currentEmoji: "👩‍💻" | "☕" | "🏖️";

  private currentSeqIndex: number;
  private remainingTime: number;
  private timer: NodeJS.Timeout;
  private timerSpeed = 1;

  constructor() {
    this.currentSeqIndex = 0;
    this.currentSet = 1;
    this.rule = LocalStorageManager.timerRule;
    this.remainingTime = LocalStorageManager.timerRule.workTime * 60; // Convert seconds
    this.currentStatusType = StatusType.Pause;
    this.timer = setInterval(() => this.decreaseTime(), 1000 / this.timerSpeed);
    this.readableRemainingTime = convertSecondToReadableTime(
      this.remainingTime
    );
    this.progress = 0;
    this.currentEmoji = this.currentSequanceEmoji();
  }

  /**
   * Update rule
   */
  @action updateRule = (updatedRule: Rule) => {
    LocalStorageManager.timerRule = updatedRule;
    this.rule = LocalStorageManager.timerRule;
    this.reset();
  };

  /**
   * Reset timer
   */
  @action reset = (skipToSet: number = 0) => {
    this.currentSeqIndex = skipToSet;
    this.currentSet = skipToSet + 1;
    this.remainingTime = LocalStorageManager.timerRule.workTime * 60; // Convert seconds
    this.currentStatusType = StatusType.Pause;
    clearInterval(this.timer);
    this.timer = setInterval(() => this.decreaseTime(), 1000 / this.timerSpeed);
    this.readableRemainingTime = convertSecondToReadableTime(
      this.remainingTime
    );
  };

  /**
   * Start timer
   */
  @action play = () => {
    if (this.currentStatusType === StatusType.Completed) this.currentSet = 1;
    this.currentStatusType = StatusType.Play;
  };

  /**
   * Pause timer
   */
  @action pause = () => {
    this.currentStatusType = StatusType.Pause;
  };

  /**
   * Timer countdown
   */
  private decreaseTime = () => {
    if (this.currentStatusType === StatusType.Play) {
      this.remainingTime -= 1;
      this.toReadableTime();
      this.checkTime();
    }
  };

  /**
   * Check remaining time
   */
  private checkTime = () => {
    if (this.remainingTime <= 0) {
      // One set ended
      if (this.currentSeqIndex !== sequance.length - 1) {
        this.currentSeqIndex += 1;
        this.remainingTime = this.getCurrentPartTime();
        this.currentSequanceEmoji();
        this.SendSequanceNotification();
        if (sequance[this.currentSeqIndex] === TimeType.Work)
          this.currentSet += 1;
      } else {
        this.currentStatusType = StatusType.Completed;
        this.currentSeqIndex = 0;
        this.remainingTime = this.getCurrentPartTime();
        this.currentSequanceEmoji();
        this.SendCompleteNotification();
        LocalStorageManager.increaseTotalCompletedSetCount();
      }
    }
  };

  /**
   * Get current time as second where from local storage
   */
  private getCurrentPartTime = () => {
    const cSeq = sequance[this.currentSeqIndex];
    switch (cSeq) {
      case TimeType.Work:
        return LocalStorageManager.timerRule.workTime * 60;
      case TimeType.ShortBreak:
        return LocalStorageManager.timerRule.shortBreak * 60;
      case TimeType.LongBreak:
        return LocalStorageManager.timerRule.longBreak * 60;
    }
  };

  /**
   * Turn seconds to readbale time
   */
  private toReadableTime = () => {
    const res = convertSecondToReadableTime(this.remainingTime);
    this.readableRemainingTime = res;
  };

  private currentSequanceEmoji = () => {
    switch (sequance[this.currentSeqIndex]) {
      case TimeType.Work:
        return "👩‍💻";
      //return "U+1F469";
      case TimeType.ShortBreak:
        return "☕";
      //return "U+2615";
      case TimeType.LongBreak:
        return "🏖️";
      //return "U+1F3D6";
      default:
        return "👩‍💻";
    }
  };

  private SendCompleteNotification() {
    if (Notification.permission !== "granted") return;
    try {
      new Notification("WELL DONE! YOU HAVE COMPLETED!");
    } catch (error) {
      console.log("Notification API error: " + error);
    }
  }

  private SendSequanceNotification() {
    if (Notification.permission !== "granted") return;

    try {
      switch (sequance[this.currentSeqIndex]) {
        case TimeType.Work:
          new Notification("LET'S WORK! 👩‍💻");
          break;

        case TimeType.ShortBreak:
          new Notification("LET'S TAKE A SHORT BREAK! ☕");
          break;

        case TimeType.LongBreak:
          new Notification("LET'S TAKE A LONG BREAK! 🏖️");
          break;
      }
    } catch (error) {
      console.log("Notification API error: " + error);
    }
  }
}

export default createContext(new TimerStore());
