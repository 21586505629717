import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import "./App.css";
import Timer from "./screens/Timer";
import Privacy from "./screens/Privacy";
import Terms from "./screens/Terms";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/terms">
          <Terms />
        </Route>
        <Route path="/privacy">
          <Privacy />
        </Route>
        <Route path="/">
          <Timer />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
