import { Rule } from "../stores/timerStore";

const TOTAL_COMPLETED_SET_COUNT_KEY: string = "total_completed_set_count";
const TIMER_RULES_KEY: string = "timer_rules_count";

const initialRule: Rule = { longBreak: 30, shortBreak: 5, workTime: 25 };

class LocalStorageManager {
  private _timerRule: Rule;

  constructor() {
    this._timerRule = initialRule;
  }

  public get timerRule(): Rule {
    const rule = localStorage.getItem(TIMER_RULES_KEY);
    if (rule !== null) {
      return JSON.parse(rule);
    } else {
      return this._timerRule;
    }
  }

  public set timerRule(v: Rule) {
    localStorage.setItem(TIMER_RULES_KEY, JSON.stringify(v));
    this._timerRule = v;
  }

  private get totalCompletedSetCount(): number {
    const lv = localStorage.getItem(TOTAL_COMPLETED_SET_COUNT_KEY);
    if (lv !== null) {
      return parseInt(lv, 10);
    } else {
      return 0;
    }
  }

  private set totalCompletedSetCount(v: number) {
    localStorage.setItem(TOTAL_COMPLETED_SET_COUNT_KEY, v.toString());
  }

  public increaseTotalCompletedSetCount() {
    this.totalCompletedSetCount = this.totalCompletedSetCount + 1;
  }
}

export default new LocalStorageManager();
