import React, { useContext } from "react";
import { observer } from "mobx-react-lite";

import TimerStore from "../stores/timerStore";
import "./ProgressDisplay.css";

const ProgressDisplay = () => {
  const timerStore = useContext(TimerStore);
  const { currentSet } = timerStore;

  return (
    <div className="d-flex flex-row mt-3">
      <div
        className={`circle-dot ${
          currentSet >= 1 ? "red-circle" : "white-circle"
        } ${currentSet === 1 ? "current" : ""}`}
      ></div>
      <div
        className={`circle-dot ${
          currentSet >= 2 ? "red-circle" : "white-circle"
        } ${currentSet === 2 ? "current" : ""}`}
      ></div>
      <div
        className={`circle-dot ${
          currentSet >= 3 ? "red-circle" : "white-circle"
        } ${currentSet === 3 ? "current" : ""}`}
      ></div>
      <div
        className={`circle-dot ${
          currentSet >= 4 ? "red-circle" : "white-circle"
        } ${currentSet === 4 ? "current" : ""}`}
      ></div>
    </div>
  );
};

export default observer(ProgressDisplay);
