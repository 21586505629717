import React, { useEffect } from "react";

import { Container, Row, Col, Card, CardBody } from "reactstrap";
import TimerDisplay from "../components/TimerDisplay";
import TimerToggler from "../components/TimerToggler";
import TimerResetButton from "../components/TimerResetButton";
import AudioToggler from "../components/AudioToggler";
import ProgressDisplay from "../components/ProgressDisplay";
import SettingsButton from "../components/SettingsButton";
import MailToLink from "../components/MailToLink";
import AppLogo from "../components/AppLogo";
import "./Timer.css";

function Timer() {
  function requestPermission() {
    if (!("Notification" in window)) {
      alert("Notification API not supported!");
      return;
    }

    Notification.requestPermission(function (result) {});
  }

  useEffect(() => {
    if (Notification.permission !== "granted") {
      requestPermission();
    }
  }, []);

  return (
    <Container fluid={true} className="h-100">
      <Row className="h-100" noGutters>
        <Col className="h-100 d-flex align-items-center justify-content-center">
          <Card className="timer-card">
            <CardBody className="d-flex align-items-center justify-content-center">
              <div className="d-flex flex-column align-items-center">
                <TimerDisplay></TimerDisplay>
                <TimerToggler />
                <ProgressDisplay></ProgressDisplay>
              </div>
              <div style={{ position: "absolute", top: 0, left: 0 }}>
                <AudioToggler />
              </div>
              <div style={{ position: "absolute", top: 50, left: 0 }}>
                <TimerResetButton />
              </div>
              <div style={{ position: "absolute", bottom: 0, left: 0 }}>
                <SettingsButton />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <MailToLink />

      <AppLogo />
    </Container>
  );
}

export default Timer;
